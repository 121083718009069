<template>
  <Page
    :subtitle="`${total} ${$tc('result', total <= 1 ? 1 : 2)}`"
    :loading="cashback_loading"
  >
    <Card v-if="$can('read', 'users.cashback')" content-class="c-table">
      <b-table
        :data="items"
        :loading="cashback_loading"
        hoverable
        mobile-cards
        striped
        backend-sorting
        sort-multiple
        :sort-multiple-data="sortingPriority"
        sort-multiple-key="shiftKey"
        :total="total"
        v-bind:row-class="
          (row, index) =>
            row.id == updated_cashback
              ? 'bg-updated'
              : row.id == deleted_cashback
              ? 'bg-deleted'
              : null
        "
        @sort="sortPressed"
        @sorting-priority-removed="sortingPriorityRemoved"
      >
        <template v-for="head in headers">
          <b-table-column
            :key="head.value"
            :field="head.value"
            :label="head.label"
            :width="head.width"
            :centered="head.centered"
            :sortable="head.sortable"
            :searchable="head.searchable"
            v-bind="head"
            header-class="sticky-header"
          >
            <template v-if="head.searchable" slot="searchable">
              <template v-if="head.filter_type == 'date'">
                <b-datepicker
                  v-model="head.input"
                  rounded
                  :placeholder="placeholders.date.day"
                  size="is-small"
                  editable
                  :min-date="new Date('01/01/2013')"
                  @input="getcashback()"
                />
                <span class="custom-date-filter" @click="showDrop(head.value)">
                  <b-icon icon="filter" size="is-small" />
                </span>
                <b-dropdown
                  :ref="`filter_drop_${head.value}`"
                  aria-role="list"
                  class="is-flex"
                >
                  <b-dropdown-item
                    aria-role="listitem"
                    @click="changeFilter(head.value, 'is')"
                  >
                    {{ $t("is") }}
                  </b-dropdown-item>

                  <b-dropdown-item
                    aria-role="listitem"
                    @click="changeFilter(head.value, 'greater')"
                  >
                    {{ $t("is") }} &gt;
                  </b-dropdown-item>

                  <b-dropdown-item
                    aria-role="listitem"
                    @click="changeFilter(head.value, 'less')"
                  >
                    {{ $t("is") }} &lt;
                  </b-dropdown-item>
                </b-dropdown>
              </template>
              <template v-else-if="head.filter_type == 'select'">
                <b-select-validation
                  v-model="head.input"
                  rounded
                  size="is-small"
                  :expanded="true"
                  @change="getcashback()"
                >
                  <template>
                    <option
                      v-for="opt in head.filter_values"
                      :key="opt[head.filter_id]"
                      :value="opt[head.filter_id]"
                    >
                      {{ opt[head.filter_text] }}
                    </option>
                  </template>
                </b-select-validation>
              </template>
              <template v-else-if="head.filter_type == 'boolean'">
                <b-select-validation
                  v-model="head.input"
                  rounded
                  expanded
                  size="is-small"
                  @change="getcashback()"
                >
                  <template>
                    <option :value="true">
                      {{ $t("yes") }}
                    </option>
                    <option :value="false">
                      {{ $t("no") }}
                    </option>
                  </template>
                </b-select-validation>
              </template>
              <template v-else>
                <b-input
                  v-model="head.input"
                  :placeholder="$root.getFilterPlaceholder(head)"
                  rounded
                  size="is-small"
                  icon-right="filter"
                  icon-right-clickable
                  @icon-right-click="showDrop(head.value)"
                  @input="getcashback"
                />
                <b-dropdown
                  :ref="`filter_drop_${head.value}`"
                  aria-role="list"
                  class="is-flex"
                >
                  <template v-if="head.filter_type == 'numeric'">
                    <b-dropdown-item
                      v-for="filter in filtersData.numeric"
                      :key="filter.value"
                      aria-role="listitem"
                      @click="changeFilter(head.value, filter.value)"
                    >
                      {{ filter.label }}
                    </b-dropdown-item>
                  </template>

                  <template v-else>
                    <b-dropdown-item
                      v-for="filter in filtersData.char"
                      :key="filter.value"
                      aria-role="listitem"
                      @click="changeFilter(head.value, filter.value)"
                    >
                      {{ filter.label }}
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
              </template>
            </template>

            <!-- site -->
            <template v-if="head.value == 'site_id'" v-slot="props">
              <img
                height="20"
                width="20"
                :src="'/img/sites/' + props.row.site_id + '.svg'"
              />
            </template>

            <template v-else-if="head.value == 'id'" v-slot="props">
              <a
                v-if="
                  $can('read', 'users.cashback') ||
                  $can('update', 'users.cashback')
                "
                href="javascript:;"
                @click="openForm(props.index, props.row)"
                >{{ props.row.id }}</a
              >
              <span v-else>{{ props.row.id }}</span>
            </template>

            <template v-else-if="head.value == 'user'" v-slot="props">
              <!-- User -->
              <router-link
                v-if="props.row.user"
                :to="`/users/${props.row.user.id}`"
              >
                {{
                  props.row.user &&
                  props.row.user.fname + " " + props.row.user.lname
                }}
              </router-link>
              <template v-else> - </template>
            </template>

            <template v-else-if="head.value == 'order_id'" v-slot="props">
              {{ props.row.order_id || "-" }}
            </template>

            <template v-else-if="head.value == 'used'" v-slot="props">
              <b-icon
                v-if="props.row.used"
                icon="check"
                type="is-success"
                custom-size="mdi-18px"
              />
              {{ props.row.used_order_id || "-" }}
            </template>

            <template v-else-if="head.value == 'amount'" v-slot="props">
              {{ props.row.amount ? props.row.amount.formatted : "-" }}
            </template>

            <template v-else-if="head.value == 'expire_at'" v-slot="props">
              <span>
                {{
                  (props.row.expire_at && props.row.expire_at.formatted) || "-"
                }}
                <span class="has-text-grey-light">{{
                  props.row.expire_at && props.row.expire_at.time
                }}</span>
              </span>
            </template>

            <template v-else-if="head.value == 'active'" v-slot="props">
              <b-icon
                :icon="props.row.active ? 'check' : 'close'"
                :type="props.row.active ? 'is-success' : 'is-danger'"
                custom-size="mdi-18px"
              />
            </template>

            <template v-else-if="head.value == 'actions'" v-slot="props">
              <b-dropdown
                aria-role="list"
                position="is-bottom-left"
                scrollable
                max-height="400px"
                append-to-body
              >
                <div slot="trigger">
                  <b-icon
                    icon="dots-vertical"
                    size="is-small"
                    class="is-clickable"
                  />
                </div>

                <b-dropdown-item
                  v-if="$can('update', 'users.cashback')"
                  aria-role="listitem"
                  @click="openForm(props.index, props.row)"
                >
                  <b-icon icon="eye" size="is-small" />
                  {{ $t("view") }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="props.row.active && $can('delete', 'users.cashback')"
                  aria-role="listitem"
                  @click="confirmDeleting(props.index, props.row)"
                >
                  <b-icon icon="delete" type="is-danger" size="is-small" />
                  {{ $t("delete") }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="!props.row.active && $can('update', 'users.cashback')"
                  aria-role="listitem"
                  @click="restoreCashback(props.index, props.row)"
                >
                  <b-icon icon="restore" size="is-small" type="is-success" />
                  {{ $t("restore") }}
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table-column>
        </template>
        <EmptyBlock slot="empty" icon="currency-usd" />
      </b-table>
      <Pagination
        :per-page="perPage"
        :per-pages="perPages"
        :total="total"
        :current-page="currentPage"
        @update-per-page="(val) => (perPage = val)"
        @update-current-page="(val) => (currentPage = val)"
      />
    </Card>
  </Page>
</template>

<script>
import moment from "moment";
import debounce from "debounce";

export default {
  data() {
    return {
      updated_cashback: null,
      deleted_cashback: null,
      sortingPriority: [],
      singleSorting: {},
      currentPage: 1,
      perPage: 50,
      perPages: [50, 100, 200],
      cashback_loading: true,
      total: 0,
      items: [],
      sites: [],

      headers: [
        {
          label: this.$t("site"),
          value: "site_id",
          sortable: true,
          searchable: true,
          width: "80",
          filter_type: "select",
          filter_text: "label",
          filter_id: "id",
          filter_values: [],
          input: "",
          filter: "is",
        },
        {
          label: this.$t("id"),
          value: "id",
          sortable: true,
          searchable: true,
          width: "80",
          filter_type: "numeric",
          input: "",
          filter: "is",
        },
        {
          label: this.$tc("customer", 1),
          value: "user",
          sortable: true,
          searchable: true,
          width: "200",
          input: "",
          filter: "contains",
        },
        {
          label: this.$tc("order", 1),
          value: "order_id",
          sortable: true,
          searchable: true,
          width: "240",
          filter_type: "numeric",
          input: "",
          filter: "is",
        },
        {
          label: this.$i18n.t("used"),
          value: "used",
          sortable: true,
          searchable: true,
          width: "100",
          filter_type: "boolean",
          centered: true,
          input: "",
        },
        {
          label: this.$t("amount"),
          value: "amount",
          sortable: true,
          searchable: true,
          width: "120",
          filter_type: "numeric",
          input: "",
          filter: "is",
        },
        {
          label: this.$t("expire_at"),
          value: "expire_at",
          sortable: true,
          searchable: true,
          width: "160",
          filter_type: "date",
          input: null,
          filter: "is",
        },
        {
          label: this.$tc("active", 1),
          value: "active",
          sortable: true,
          searchable: true,
          width: "100",
          filter_type: "boolean",
          centered: true,
          input: "",
        },
        {
          label: this.$i18n.t("actions"),
          value: "actions",
          sortable: false,
          width: "80",
          centered: true,
        },
      ],
    };
  },
  watch: {
    perPage() {
      this.getcashback();
    },
    currentPage() {
      this.getcashback();
    },
  },
  created() {
    this.updateTitle(this.$tc("cashback", 2));
  },
  mounted() {
    if (this.$can("read", "users.cashback")) {
      this.sortPressed("id", "asc", { shiftKey: true });
    }
  },
  methods: {
    closeDrop() {
      this.$refs["dropdown"].toggle();
    },
    showDrop(field) {
      this.$refs[`filter_drop_${field}`][0].toggle();
    },
    changeFilter(field, value) {
      let index = this.headers.findIndex((h) => h.value == field);
      this.headers[index].filter = value;
      if (this.headers[index].input) this.getcashback();
    },
    sortPressed(field, order, event) {
      if (event["shiftKey"]) {
        let existingPriority = this.sortingPriority.filter(
          (i) => i.field === field
        )[0];
        if (existingPriority) {
          existingPriority.order =
            existingPriority.order === "desc" ? "asc" : "desc";
        } else {
          if (this.singleSorting.field)
            this.sortingPriority.push(this.singleSorting);
          this.sortingPriority.push({ field, order });
        }
        this.singleSorting = {};
      } else {
        this.sortingPriority = []; // [{field, order}]
        this.singleSorting = { field, order };
      }
      this.getcashback();
    },
    sortingPriorityRemoved(value) {
      this.sortingPriority = this.sortingPriority.filter(
        (priority) => priority.field !== value
      );
      this.getcashback();
    },
    getcashback: debounce(function () {
      let api_params = `?page=${this.currentPage}&per_page=${this.perPage}`;

      if (this.sortingPriority.length > 1) {
        let sortArr = [];
        for (let i = 0; i < this.sortingPriority.length; i++) {
          const sort = this.sortingPriority[i];
          sortArr.push(`${sort.field}:${sort.order}`);
        }
        api_params += `&sorting=${sortArr.join(",")}`;
      } else if (this.sortingPriority.length == 1) {
        api_params += `&sorting=${this.sortingPriority[0].field}:${this.sortingPriority[0].order}`;
      } else if (this.singleSorting.field) {
        api_params += `&sorting=${this.singleSorting.field}:${this.singleSorting.order}`;
      }

      let searchArr = [];
      for (let i = 0; i < this.headers.length; i++) {
        const value = this.headers[i];
        if (
          ["user", "order_id"].includes(value.value) &&
          value.input &&
          value.input.length != 0 &&
          value.input.length < 3
        )
          return;
        else if (value.input || typeof value.input == "boolean") {
          let obj = {
            id: value.value,
            selected:
              value.value == "ends_at" || value.value == "starts_at"
                ? moment(value.input).format("YYYY-MM-DD")
                : value.input,
          };
          if (value.filter) obj.operator = value.filter;
          searchArr.push(obj);
        }
      }
      if (searchArr.length)
        api_params += `&filters=${btoa(JSON.stringify(searchArr))}`;

      this.cashback_loading = true;

      this.$axios
        .get("/cashback" + api_params)
        .then((res) => {
          let siteHeaderIndex = this.headers.findIndex(
            (h) => h.value == "site_id"
          );

          if (!this.headers[siteHeaderIndex].filter_values.length)
            this.headers[siteHeaderIndex].filter_values =
              res.data.filters.sites;
          this.items = res.data.list ? res.data.list : [];
          this.sites = res.data.filters.sites || [];
          this.total = res.data.pagination.total;
        })
        .catch((e) => {
          const code = parseInt(e.response && e.response.status);
          if (code) {
            if (code === 404) {
              this.items = [];
              this.total = 0;
            } else {
              this.clientError(e);
            }
          }
        })
        .finally(() => (this.cashback_loading = false));
    }, 200),
    confirmDeleting(index, cashback) {
      this.$buefy.dialog.confirm({
        message: `<b>${this.$t("are_you_sure")}</b>`,
        confirmText: this.$t("confirm"),
        cancelText: this.$t("cancel"),
        type: "is-danger",
        onConfirm: () => this.deleteCashback(index, cashback),
      });
    },
    deleteCashback(index, cashback) {
      this.$axios
        .delete("users/" + cashback.user.id + "/cashback/" + cashback.id)
        .then(() => {
          this.items.splice(index, 1, { ...cashback, active: false });
          this.deleted_cashback = cashback.id;

          setTimeout(() => {
            this.deleted_cashback = null;
          }, 2000);
        })
        .catch((e) => this.$root.clientError(e));
    },
    restoreCashback(index, cashback) {
      this.$axios
        .patch(
          "users/" + cashback.user.id + "/cashback/" + cashback.id + "/restore"
        )
        .then(() => {
          this.items.splice(index, 1, { ...cashback, active: true });
          this.updated_cashback = cashback.id;

          setTimeout(() => {
            this.updated_cashback = null;
          }, 2000);
        })
        .catch((e) => this.$root.clientError(e));
    },
    openForm(index, cashback) {
      if (
        this.$can("read", "users.cashback") ||
        this.$can("update", "users.cashback")
      ) {
        // cu : create+update
        let default_site_id = cashback.site_id;

        let orders =
          cashback && cashback.order_id ? [{ id: cashback.order_id }] : [];

        let cashback_form = { ...cashback };
        if (index === -1) {
          cashback_form = {
            ...cashback,
          };
        }
        let options = {
          index: index,
          cashback: cashback_form,
          default_site_id: default_site_id,
          orders: orders,
          sites: this.sites,
          user_id: cashback.user.id,
          action: "r",
        };
        this.openDrawer("cashbackForm", options);
      }
    },
  },
};
</script>
